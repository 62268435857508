// LIBRARIES
@import '~bootstrap/scss/bootstrap';
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import '~js-datepicker/src/datepicker';

//@import '/node_modules/tablesort/tablesort';
//@import '/node_modules/trix/dist/trix';

$blue: #095596;

body { background-color: #F3F3F3; font-family: Lato, "Helvetica Neue", Arial, sans-serif; }
@media (min-width: 1440px) {
  .container { max-width: calc(100% - 80px); }
}
a { color: $blue; }
.btn-primary {
  background-color: $blue;
  border-color: #08477E;
  color: #FFF;
}

.page-signin {
  .input-group-addon {
    padding: 16px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #767676;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #CBD5DD;
    border-radius: 0;
  }
}
.page-signin .logo, .page-signup .logo, .page-forgot .logo {
  a { margin: 20px 0; }
  font-size: 46px;
  font-weight: bold;
}
.page-signin .logo img, .page-signup .logo img, .page-forgot .logo img { width: 400px; }
.page-signin .form-container { width: 500px; margin: 0 auto; }
.page-signin .signin-header, .page-signup .signin-header, .page-forgot .signin-header {
  background-color: #FFF;
  padding: 60px 0 30px 0;
  margin-bottom: 25px;
}

.top-header .logo img { width: 70px; }

body.dashboard .dashboard-actions { padding-bottom: 10px; }
.btn { border-radius: 0px; }
.btn-info { background-color: $blue; border-color: #08477e; }
.btn-info:hover { background-color: #063a66; border-color: #042744; }
.btn-warning { background-color: #EEC95A; border-color: #ecc143; }
.btn-warning:hover { background-color: #e9ba2c; border-color: #dbaa17; }
.btn-success { background-color: #72a94e; border-color: #669846; }
.btn-success:hover { background-color: #5a863e; border-color: #4a6e33; }
.btn-danger { background-color: #E87352; border-color: #e5613b; }
.btn-danger:hover { background-color: #e24f25; border-color: #c9411b; }
.form-control { border-radius: 0; }
.navbar { margin-bottom: 40px; }
.navbar-brand.logo { position: absolute; }
.navbar-brand.logo img { width: 75px; }
.navbar-collapse { padding-left: 80px; }
.text-thin { font-weight: 300; }

.pagination>li>a, .pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: 1px;
  line-height: 1.42857143;
  color: $blue;
  text-decoration: none;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
}
.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus {
z-index: 3;
  color: #fff;
  cursor: default;
  background-color: $blue;
  border-color: $blue;
}

.panel-default {
  border-color: #d5d5d5;
}
.panel {
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05);
}
.panel .panel-heading {
  text-transform: uppercase;
  font-size: 1.1em;
}
.panel-default>.panel-heading {
  color: #767676;
  background-color: #f6f6f6;
  border-color: #e9e9e9;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
}
.panel .panel-body {
  padding: 10px;
}

button.datatable-sorter { border: none; background: transparent; }
.datatable-search input {
  margin-bottom: 30px;
  display: block;
  width: 300px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
li.datatable-pagination-list-item button { border: none; }
li.datatable-pagination-list-item { margin: 4px 2px; list-style: none; display: inline-block; }
ul.datatable-pagination-list { padding-left: 0; }


.callout-info {
  background-color: #f4f8fa;
  border-color: #A5C6DB;
}
.callout {
  margin: 10px 0;
  padding: 10px;
  border-left: 3px solid #eee;
}
#new-exam-result #query { width: 300px; }
.selection-display { width: 260px; }
.results ul {
  margin-top: -1rem;
  border: solid 1px #81D3B9;
}
.selectable.separator {
  border-bottom: solid 1px #81D3B9;
}
.selectable:hover {
  background: #81D3B9;
  color: #FFF;
  cursor: pointer;
}
.masquerade-footer {
  position: sticky;
  bottom: 0;
  background-color: $blue;
  padding: 15px;
  text-align: right;
  color: #FFF;
  a { color: #FFF; }
}

@media print {
  .no-print { display: none; }
}
